import ReactShadowRoot from 'react-shadow-root';

/**
 * Only use this for components which are loading external css.
 * EX: Showing an banner or external newsletter.
 *
 * Polyfilling shadowdom requires polyfilling Proxy. That is:
 * 1) Hard / Time Consuming
 * 2) Heavyweight / slow libs (Poor Browser performance)
 * 3) Fixes things for 0.4% of our user's.
 *
 * For now, IE can display as normal.
 * Worse case: Css is all messed up for IE only. (Kinda usual for that to happen)
 * Best case: The css didn't conflict at all and the component is fine.
 */
const withShadowRoot = Component => {
   const isIE = Browser.name === 'ie';

   // eslint-disable-next-line react/display-name -- FIXME: Component definition is missing display name
   return props => {
      if (isIE) {
         return <Component {...props} />;
      }
      return (
         <div>
            <ReactShadowRoot>
               <Component {...props} />
            </ReactShadowRoot>
         </div>
      );
   };
};

export default withShadowRoot;
